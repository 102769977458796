.box {
  flex-wrap: wrap;
}

.title {
  color: #333333;
  font-size: 42px;
  margin-top: 70px;
}

.img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  background-color: #f5f5f5;
}

.row {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}

.con_width {
  width: 50%;
  background-color: #F8F8F8;
}

.con {
  width: 500px;
  height: 100%;
  box-sizing: border-box;
}

.con_title {
  width: 100%;
  text-align: left;
  font-size: 22px;
  margin-bottom: 40px;
}

.con_desc {
  font-size: 14px;
}