.nav {
  height: 80px;
  font-size: 16px;
  color: #fff;
  background-color: #7DBD9F;
}

.menu {
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin: 0 50px;
  cursor: pointer;
  text-align: center;
  scroll-behavior: smooth;
}

.menu:hover {
  color: #7DBD9F;
  border-radius: 20px;
  background-color: #fff;
}

.content {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-width: 998px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}