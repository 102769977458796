.container {
  margin-top: 50px ;
  background-color: #f5f5f5;
}

.content {
  width: 50%;
  padding: 50px 5%;
  color: #333333;
  box-sizing: border-box;
}

.img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.desc {
  font-size: 16px;
}

.desc_space {
  margin-top: 20px;
}