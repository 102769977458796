.box {
  width: 970px;
  border: 2px solid #F2F2F2;
  padding: 40px 30px 45px;
  margin-bottom: 40px;
  position: relative;
}

.name {
  font-size: 20px;
  color: #333333;
  margin-bottom: 15px;
}

.sub {
  font-size: 14px;
  /* color: #DFDFDF; */
  color: #7f7a7a;
  margin-bottom: 10px;
}

.sub_bottom {
  margin-bottom: 20px;
}

.icon {
  margin-right: 15px;
}

.bottom {
  width: calc(100% - 60px);
  height: 45px;
  color: #333333;
  font-size: 14px;
  border-top: 1px dotted #DFDFDF;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.ability {
  color: #333333;
  margin-top: 40px;
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
}

.desc {
  font-size: 14px;
  margin-bottom: 5px;
}

.subDesc{
  text-indent: 2em;
}

.contact {
  font-size: 14px;
  color: #333333;
  margin: 30px 0;
}