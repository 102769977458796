.box {
  width: 33.333333333333%;
  height: 750px;
  overflow: hidden;
  position: relative;
}

.bg {
  width: 100%;
  height: 750px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.box:hover .bg {
  transform: scale(1.08);
  transition: transform .5s linear;
}

.con {
  width: 320px;
  position: absolute;
  color: #FFF;
}

.title {
  font-size: 36px;
}

.subtitle {
  font-size: 22px;
}

.desc {
  font-size: 18px;
}