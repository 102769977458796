.con {
  margin-bottom: 70px;
  flex-wrap: wrap;
}

.title {
  font-size: 42px;
  color: #4B4B4B;
  margin-top: 70px;
}

.box {
  width: 450px;
  height: 460px;
  margin-top: 45px;
  box-shadow: 0 5px 15px 2px #f5f5f5;
}

.img {
  width: 100%;
  height: 380px;
  object-fit: cover;
  background-color: #f5f5f5;
}

.name {
  height: 80px;
  line-height: 80px;
  color: #333333;
  font-size: 28px;
}