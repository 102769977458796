.bg {
  width: 100%;
  object-fit: cover;
}

.bg1 {
  height: 100%;
}

.bg2 {
  height: 100%;
}

.bg3 {
  height: 100%;
}

.banner_con {
  width: 1062px;
  text-align: center;
  color: #FFF;
}

.banner_title {
  font-size: 50px;
  letter-spacing: 4px;
}

.banner_desc {
  font-size: 22px;
  letter-spacing: 3.5px;
  line-height: 36px;
}