.content {
  height: 790px;
}

.con {
  width: 492px;
  font-size: 30px;
  color: #333333;
}

.green {
  font-size: 36px;
  color: #7EBEA0;
}

.img {
  width: 190px;
  height: 278px;
  object-fit: contain; 
  cursor: pointer;
}

.box {
  width: 671px;
  height: 594px;
  position: relative;
}

.box_one {
  position: absolute;
  top: 0;
}

.box_two {
  position: absolute;
  top: 132px;
  z-index: 2;
}

.two_left {
  margin-right: -50px;
}

.two_center {
  z-index: 3;
}

.two_right {
  margin-left: -50px;
}

.box_three {
  position: absolute;
  bottom: 0;
  z-index: 4;
}

.three_edge_left {
  margin-right: -80px;
}

.three_left {
  margin-right: -80px;
  z-index: 5;
}

.three_center {
  z-index: 6;
}

.three_right {
  margin-left: -80px;
  z-index: 5;
}

.three_edge_right {
  margin-left: -80px;
}