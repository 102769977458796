.content {
  margin: 20px 0;
  color: #333333;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.componey {
  font-size: 24px;
  margin-bottom: 20px;
}

.desc {
  font-size: 16px;
  line-height: 32px;
}