
.container {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  /* min-width: 998px; */
  min-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.content {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  /* min-width: 998px; */
  min-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.content_max {
  width: 100%;
  max-width: 1440px !important;
  margin: 0 auto;
}

.max_width {
  width: 100%;
  max-width: 1920px !important;
  margin: 0 auto;
}

.min_width {
  /* min-width: 998px; */
  width: 100%;
  min-width: 1440px !important;
}

.row {
  height: 100%;
}

.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  /* min-width: 998px; */
  min-width: 1440px;
  object-fit: cover;
  margin: 0 auto;
}

.content_space {
  padding: 0 30px;
}

.title {
  font-size: 42px;
  color: #4B4B4B;
}

.display_none {
  display: none !important;
}

.display_block {
  display: block;
}

.space_top {
  margin-top: 40px;
}

.space_bottom {
  margin-bottom: 40px;
}

.item_bottom {
  margin-bottom: 70px;
}

.item_top {
  margin-top: 70px;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.flex_row_start_center {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.flex_row_start_between {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.flex_row_start_around {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.flex_row_center_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex_row_center_between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex_row_center_around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flex_row_end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.flex_row_start_end {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.flex_row_center_end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flex_row_end_end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex_row_end_between {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.flex_column_start_center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex_column_start_between {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}


.flex_column_start_around {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.flex_column_center_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_column_center_between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex_column_center_around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.flex_column_end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flex_column_start_end {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.flex_column_center_end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.flex_column_end_end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

