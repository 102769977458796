.title {
  font-size: 42px;
  color: #4B4B4B;
  margin-top: 70px;
}

.bg {
  height: 500px;
  color: #fff;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg_con {
  width: 576px;
  height: 500px;
}

.bg_title {
  font-size: 22px;
}

.bg_desc {
  font-size: 16px;
  text-align: center;
}

.box_bg {
  background-color: #F5F5F5;
}

.box {
  height: 450px;
  color: #666666;
}


.box_con {
  width: 573px;
}

.con_title {
  font-size: 22px;
}

.con_desc {
  font-size: 14px;
}