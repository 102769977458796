.content {
  color: #333333;
  margin-top: 70px;
  margin-bottom: 30px;
}

.title {
  font-size: 42px;
}

.row {
  width: 100%;
  height: 100%;
  background-color: #EEF1F5;
}

.box {
  width: 100%;
  flex-wrap: wrap;
  color: #666666;
}

.img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  background-color: #f5f5f5;
}

.con {
  width: 50%;
  height: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  background-color: #EEF1F5;
}

.con_title {
  font-size: 22px;
  letter-spacing: 3px;
  margin-bottom: 25px;
}

.con_subtitle {
  font-size: 18px;
  letter-spacing: 2px;
  margin: 25px 0;
}

.con_desc {
  font-size: 14px;
}