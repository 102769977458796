.bg {
  width: 100%;
  padding-bottom: 90px;
  background-color: #F7F7F7;
}

.heading {
  color: #666 !important;
}

.img {
  margin-top: 100px;
  object-fit: contain;
}