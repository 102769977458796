.content {
  color: #333333;
  margin-top: 70px;
}

.title {
  font-size: 42px;
}

.img {
  width: 810px;
  height: 350px;
  object-fit: cover;
}

.con {
  margin-left: 100px;
}

.desc {
  font-size: 20px;
  letter-spacing: 2px;
  margin: 5px 0;
}