.content {
  margin-top: 70px;
}

.title {
  color: #4B4B4B;
  font-size: 42px;
}

.box {
  height: 300px;
  height: 400px;
  overflow: hidden;
  position: relative;
  color: #fff;
}

.box:hover .bg {
  transform: scale(1.08);
  transition: transform .5s linear;
}

.bg {
  width: 300px;
  height: 400px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.box_con {
  padding: 25px;
  position: absolute;
}

.box_title {
  font-size: 22px;
}

.box_desc {
  font-size: 16px;
  text-align: center;
}