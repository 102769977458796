.bg {
  width: 100%;
  height: 1020px;
  background-color: #F3F3F3;
  /* z-index: -2; */
}

.con {
  width: 100%;
  height: 100%;
  color: #333333;
  position: relative;
}

.heading {
  color: #666 !important;
}

.img_center {
  width: 561px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  width: 83px;
  object-fit: contain;
  z-index: 4;
}

.title {
  font-size: 22px;
  margin-bottom: 5px;
}

.box {
  height: 175px;
}

.desc {
  font-style: 14px;
  margin-bottom: 5px;
}

.icon_box {
  cursor: pointer;
  position: absolute;
  z-index: 3;
}

.live{
  position: relative;
  width: 83px;
  height: 83px;
  margin-right: 25px;
  cursor: pointer;
}
.live img{
  width: 83px;
  height: 83px;
}
@keyframes living {
   0%{
       transform: scale(1);
       opacity: 0.5;  
   }
   50%{
       transform: scale(1.5);  
       opacity: 0;   /*圆形放大的同时，透明度逐渐减小为0*/
   }
   100%{
       transform: scale(1);
       opacity: 0.5;
   }
}
.live span{
   position: absolute;
   width: 83px;
   height: 83px;
   left: 0;
   bottom: 0;
   background: #7a7a7a !important;
   border-radius: 50%;
   animation: living 1.9s linear infinite;
   z-index: -1;
}
.live span:nth-child(2){
   animation-delay: 1.5s; /*第二个span动画延迟1.5秒*/
}