.contain {
  display: flex;
  justify-content: center;
  background-color: #414141;
  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 50px 30px;
}

.left {
  display: flex;
}

.nav_menu {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
}

.title {
  color: #FFFFFF;
  font-size: 18px;
  margin-bottom: 15px;
}

.subtitle {
  color: #D2D2D2;
  font-size: 14px;
  margin: 4px 0;
}

.href {
  cursor: pointer;
}

.qr_title {
  font-size: 18px;
  color: #FFFFFF;
}

.qr_subtitle {
  font-size: 14px;
  color: #fff;
  margin: 4px 0;
}

.right {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

.qr_content {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.qr_code {
  width: 72px;
  height: 72px;
  margin-right: 15px;
}

.qr_text {
  display: flex;
  flex-direction: column;
}

.copyright {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: #B2B2B2;
  font-size: 14px;
}

.link{
  padding-left: 10px;
  color: #b2b2b2
}
