
.content {
  width: 100%;
  margin-top: 36px;
}

.margin_left_45 {
  margin-left: 45px;
}

.margin_right_45 {
  margin-right: 45px;
}

.margin_about_20 {
  margin: 0 20px;
}

.row {
  width: 100%;
  margin-bottom: 20px;
}

.box {
  padding: 50px;
  cursor: pointer;
}

.con {
  margin-left: 100px;
  color: #4B4B4B;
}

.box_con {
  color: #4B4B4B;
}

.name {
  font-size: 20px;
}

.desc {
  font-size: 14px;
}

.bottom_wrap{
  padding: 0 10px;
}

.item_long {
  width: 100%;
  height: 280px;
  background-color: #DFE7F2;
}

.item {
  width: 100%;
  height: 280px;
  background-color: #DFE7F2;
}

.item_short {
  width: 100%;
  height: 350px;
  cursor: pointer;
  background-color: #DFE7F2;
}

.img {
  height: 200px !important;
  object-fit: contain;
}

.short_box {
  padding: 20px;
}

.short_margin {
  margin-bottom: 20px;
}

.short_name {
  margin-bottom: 20px;
}