.subtitle {
  font-size: 30px;
  margin-bottom: 68px;
}

.box {
  height: 500px;
  overflow: hidden;
  position: relative;
  object-fit: cover;
}

.con {
  font-size: 42px;
  color: #fff;
  position: absolute;
}

.shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
}

.name_en {
  margin-bottom: 52px;
}

.name_cn {
  margin-bottom: 65px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn {
  width: 150px;
  height: 54px;
  line-height: 54px;
  font-size: 16px;
  text-align: center;
  color: #FFF !important;
  border: 2px solid #FFF;
  cursor: pointer;
}

.box:hover .img {
  transform: scale(1.08);
  transition: transform .5s linear;
}

.box:hover .shadow {
  background-color: rgba(0, 0, 0, .2);
  transition: background-color .5s linear;
}

.box_item {
  height: 450px !important;
}
