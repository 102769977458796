.newsInfo {
  margin-top: -80px;
  padding-top: 80px;
}

.top {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px 2px #f5f5f5;
  background-color: #F5F5F5;
}

.top_con {
  width: 50%;
  height: 100%;
  color: #656565;
  padding: 30px 68px;
  box-sizing: border-box;
  background-color: #F5F5F5;
}

.top_img {
  margin: 0 auto;
  /* width: 50% ; */
  height: 100% ;
  max-height: 400px;
  object-fit: contain;
  /* background-color: #D9DDE2; */
}

.top_title {
  width: 100%;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top_date {
  width: 100%;
  font-size: 14px;
}

.top_subtitle {
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.con {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 450px);
  grid-gap: 45px 0;
  padding-bottom: 45px;
  margin-top: 45px;
}

.box {
  width: 450px;
  height: 400px;
  color: #656565;
  box-shadow: 0 5px 15px 2px #f5f5f5;
  cursor: pointer;
}

.box:last-child {
  margin-right: auto;
}

.img {
  width: 450px;
  height: 300px;
  max-height: 300px;
  object-fit: cover;
  background: #D9DDE2;
}

.img img {
  height: 100% !important;
}

.box_con {
  height: 100px;
  padding: 15px 30px;
  box-sizing: border-box;
}

.title {
  width: 100%;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date {
  font-size: 14px;
}