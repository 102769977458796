.title {
  color: #333333;
  font-size: 42px;
  margin-top: 30px;
}

.box {
  width: 330px;
  height: 400px;
  color: #ffffff;
  box-sizing: border-box;
  background-color: #4b0000;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  position: relative;
}

.box_con {
  width: 100%;
  height: 230px;
  padding: 30px;
  box-sizing: border-box;
  position: absolute;
  bottom: -100px;
}

.box:hover .box_con {
  bottom: 0;
  transition: bottom .4s;
}

.box_title {
  width: 100%;
  font-size: 32px;
  margin: 0 auto;
  text-align: center;
}

.line {
  width: 50px;
  height: 3px;
  text-align: center;
  margin: 40px auto 30px;
  background-color: #fff;
}

.desc {
  font-size: 14px;
}