.box {
  box-sizing: border-box;
  padding: 40px 30px !important;
}

.img {
  width: 220px;
  height: 220px;
  object-fit: contain;
  margin-right: 248px;
}

.con {
  width: 878px;
  color: #333333;
}

.name {
  font-size: 30px;
  color: #666;
}

.model {
  font-size: 28px;
}

.title {
  font-size: 24px;
}

.desc {
  font-size: 16px; 
  white-space: pre-wrap;
}

.content {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-width: 998px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}