.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bg_con {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 893px;
  color: #333333;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg_about {
  width: 100%;
  max-width: 1440px;
  margin-top: 213px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.bg_left {
  color: #333333;
}

.title {
  margin-top: 40px;
  font-size: 30px;
}

.desc {
  width: 400px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height:36px
}

.qr_box {
  width: 158px;
}

.qr_code {
  padding: 5px;
  margin-bottom: 30px;
  background-color: #fff;
}

.qr_text {
  font-size: 24px;
  color: #333333;
}

.margin_right_54 {
  margin-right: 54px;
}

.btn {
  width: 231px;
  height: 63px;
  margin-top: 50px;
  background: #7EBEA0 !important;
  border-color: #7EBEA0 !important;
}

.phone {
  width: 668px;
  height: 600px;
}

.phone_con {
  height: 166px;
  object-fit: contain;
}

.desc_con {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin-top: 120px;
}

.desc_con .box_wrap {
  width: 100%;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 280px;
  border-radius: 12px;
  box-shadow: 0 0 20px 20px #f2f2f2;
  color: #44555F;
}

.icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.icon_name {
  font-size: 24px;
  margin: 40px 0 30px;
}

.icon_desc {
  font-size: 16px;
}

.box_top {
  margin-top: 46px;
}

.box_right {
  margin-right: 40px;
}

.box_bottom {
  margin-bottom: 45px;
}
