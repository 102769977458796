.title {
  font-size: 42px;
  color: #4B4B4B;
  margin-top: 70px;
}

.row {
  width: 1220px;
  height: 450px;
  padding: 60px 0;
  color: #666;
  box-sizing: border-box;
}

.bg {
  background-color: #EEF1F5;
}

.bg2 {
  background-color: #F5F5F5;
}

.img {
  width: 480px;
  height: 330px;
  object-fit: cover;
}

.con {
  width: 573px;
}

.con_title {
  font-size: 22px;
}

.con_desc {
  font-size: 14px;
}