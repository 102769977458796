.box {
  width: 100%;
  color: #333333;
  padding: 40px 30px !important;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
}

.desc {
  white-space: pre-wrap;
}

.content {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-width: 998px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}