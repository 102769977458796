.title {
  color: #333333;
  font-size: 42px;
  margin-top: 70px;
}

.box {
  width: 100%;
  height: 613px;
  color: #333333;
  background-color: #f5f5f5;
  object-fit: cover;
}

.box_title {
  font-size: 28px;
  margin-bottom: 100px;
  letter-spacing: 5px;
}

.box_item {
  width: 128px;
  height: 215px;
  padding: 30px 0;
  margin: 0 5px;
  box-sizing: border-box;
  background-color: #fff;
}

.box_num {
  font-size: 30px;
}

.box_desc {
  font-size: 18px;
}