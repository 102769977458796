.content {
  margin-bottom: 20px;
  color: #333333;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.componey {
  font-size: 24px;
  margin-bottom: 20px;
}

.desc {
  font-size: 16px;
}

.map {
  margin: 20px 0 50px;
  height: 300px;
  background-color: #f5f5f5;
}

.img {
  margin-top: 20px;
  height: 450px;
  object-fit: cover;
  background-color: #f5f5f5;
}

.position {
  color: #7EBEA0 !important;
}