.contain {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #ececec;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 10;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  height: 80px;
  max-width: 1440px;
  padding: 0 60px;
  box-sizing: border-box;
}

.logo {
  height: 35px;
}

.nav_menu .menu_wrap{
  /* 必须给固定值，不然在safari里菜单会变成 ... */
  width: 500px;
  justify-content: flex-end;
}

.item {
  height: 80px;
  line-height: 80px;
  padding: 0 30px !important;
  cursor: pointer;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
}

.sub_item {
  height: 80px;
  line-height: 80px;
  padding: 0 10px !important;
  cursor: pointer;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
}