.content {
  padding-bottom: 60px;
}

.con {
  width: 100%;
  flex-wrap: wrap;
}

.title {
  font-size: 42px;
  color: #4B4B4B;
}

.box {
  margin: 40px 50px 0;
}

.img {
  width: 170px;
  height: 82px;
  object-fit: contain;
}