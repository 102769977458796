.content {
  width: 970px;
  margin: 0 auto;
}

.bread {
  color: #333333 !important;
  font-size: 20px !important;
  margin: 30px 0 20px !important;
}

.desc {
  width: 970px !important;
  margin: 50px 0;
}

.desc img {
  max-width: 970px !important;
}

.date_box {
  font-size: 14px;
  color: #666666;
}

.source {
  margin-left: 20px;
}

.href {
  cursor: pointer;
}

.prev_next {
  width: 970px !important;
  margin: 30px auto;
}

.label {
  margin-right: 10px;
  color: #4B4B4B;
}

.article {
  color: #333333;
}

.con_title {
  max-width: 350px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 30px;
}