#news{
  .ant-image-error{
    background: #D9DDE2;
    .ant-image-img{
      margin: 0 auto;
      width: 50%;
    }
  }
  .ant-image{
    .ant-image-mask:hover{
      opacity: 0;
    }
  }
}
