.content {
  padding: 70px;
  height: 550px;
}

.con {
  color: #7DBD9F;
  font-size: 28px;
  margin-top: 15px;
}

.img {
  width: 363px;
  height: 242;
  object-fit: contain;
}