.title {
  font-size: 42px;
  color: #4B4B4B;
  margin-top: 70px;
}

.bg {
  background-color: #F5F5F5;
}

.box {
  padding: 60px 0;
  color: #666666;
}

.con {
  width: 742px;
}

.box_title {
  font-size: 22px;
}

.box_desc {
  font-size: 16px;
}

.img {
  width: 480px;
  height: 330px;
  object-fit: cover;
}

.row_con {
  width: 328px;
  color: #666666;
}