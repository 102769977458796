.content {
  color: #333333;
  margin-top: 70px;
}

.title {
  font-size: 42px;
  text-align: right;
}

.img {
  width: 810px;
  height: 350px;
  object-fit: cover;
}

.con {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 450px);
  grid-gap: 40px 0;
}

.box {
  width: 450px;
  height: 400px;
  box-shadow: 0 5px 15px 2px #f5f5f5;
}

.box:hover .bg {
  transform: scale(1.08);
  transition: transform .5s linear;
}

.bg_box {
  width: 100%;
  overflow: hidden;
}

.bg {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.desc {
  width: 300px;
  height: 100px;
  font-size: 20px;
  letter-spacing: 1px;
  color: #333333;
}

.bg_title {
  font-size: 48px;
  color: #fff;
  position: absolute;
  white-space: nowrap
}