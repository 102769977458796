.bg {
  padding-top: 70px;
  background-color: rgb(243, 243, 243);
}

.title {
  color: #707A7C !important;
  margin-bottom: 125px;
}

.box {
  position: relative;
}

.btn {
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  background-color: #7EBEA0;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  z-index: 2;
}

.btn_1 {
  left: 30px;
}