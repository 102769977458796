.content {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  margin: 0 auto;
  max-width: 1440px !important;
}

.box {
  display: flex;
  width: 700px;
  height: 320px;
  margin: 10px;
  padding: 85px 70px 0 50px;
  box-sizing: border-box;
  color: #666;
  background-color: #F5F5F5;
  cursor: pointer;
  position: relative;
}

.img_box {
  min-width: 150px;
  height: 150px;
  margin-right: 100px;
  text-align: center;
  border: 1px solid #f5f5f5;
}

.img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
}

.con {
  height: 100%;
  position: relative;
}

.name {
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.brief {
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.btn {
  width: 115px;
  height: 40px;
  font-size: 16px;
  margin-bottom: 40px;
  background-color: #838383 !important;
  border: none !important;
}