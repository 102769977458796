.bg {
  color: #666666;
  padding-bottom: 88px;
  background-color: #EBEBEB;
}

.title {
  font-size: 36px;
}

.desc {
  font-size: 18px;
}

.img {
  height: 814px;
  object-fit: cover;
}

.img_box {
  overflow: hidden;
}

.img_bg {
  width: 100%;
  object-fit: contain;
}