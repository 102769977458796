.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 53px 0;
  color: #333333;
}

.title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.box {
  width: 330px;
}

.img_box {
  width: 100%;
}

.img {
  width: 330px;
  height: 246px;
  object-fit: cover;
  background-color: #F8F8F8; 
}

.introduce {
  width: 330px;
  height: 260px;
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #F8F8F8;
}

.name {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}

.desc {
  font-size: 16px;
  color: #666666;
  line-height: 26px;
}