.bg {
  background-color: #EBEBEB;
}

.con {
  width: 330px;
  color: #666666;
}

.titile {
  font-size: 22px;
}

.subtitle {
  font-size: 18px;
}

.desc {
  font-size: 14px;
}

.img {
  width: 530px;
  height: 311px;
  object-fit: contain;
}