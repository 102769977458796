.nav_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  font-size: 20px;
  color: #333333;
  background-color: #F5F5F5 !important;
}

.menu_item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 155px;
  height: 135px;
  border-bottom: 0 !important;
  font-size: 20px;
}

.menu_item::after{
  display: none;
}

.menu_item:hover {
  background-color: #7EBEA0;
  border-bottom: 0 !important;
}

.other_menu:hover {
  color: #fff !important;
}

.menu_action {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 155px;
  height: 150px;
  border-radius: 8px;
  color: #fff !important;
  background-color: #7EBEA0;
  border-bottom: 0 !important;
}

.menu_action:hover {
  background-color: #7EBEA0 !important;
}